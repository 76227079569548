<template>
  <nav>
    <v-app-bar class="navigation" v-if="isLoggedIn" :extended="showMobileSearch" :color="$servicex.branding.color" text
      app>
      <v-app-bar-nav-icon @click="drawer = !drawer" class="white--text"></v-app-bar-nav-icon>
      <component is="brand"></component> <v-spacer></v-spacer>
      <span v-if="!userIsAgent" class="mr-3 d-none d-md-block" style="width: 80%; max-width: 800px">
        <GlobalSearch :navigateOnClick="navigateOnClick" />
      </span>
      <v-spacer></v-spacer>
      <span v-if="!userIsAgent" class="d-block d-md-none">
        <v-btn @click="showMobileSearch = !showMobileSearch" icon color="white">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </span>
      <!-- <v-btn v-if="!isLoggedIn" text color="white" @click="SignIn">
        <span>Sign In</span>
        <v-icon right>mdi-login</v-icon>
      </v-btn> -->

      <v-menu bottom left v-if="isLoggedIn" min-width="180" class="mt-8">
        <template v-slot:activator="{ on, attrs }">
          <div icon color="white" v-bind="attrs" v-on="on">
            <span style="color: white" class="d-none d-md-inline mr-2">{{ user.firstName }} {{ user.lastName }}</span>
            <v-icon color="white">mdi-account-circle</v-icon>
          </div>
        </template>

        <v-list class="py-0">
          <!-- <v-list-item @click="SignOut">
            <v-list-item-title
              ><v-icon class="mr-3">mdi-account-details</v-icon>My
              Account</v-list-item-title
            >
          </v-list-item>
          <v-divider></v-divider> -->
          <v-list-item>
            <v-list-item-title>{{ user.firstName }} {{ user.lastName }}</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="SignOut">
            <v-list-item-title><v-icon class="mr-3">mdi-logout</v-icon>Sign
              out</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- <v-btn v-if="isLoggedIn" text color="grey" @click="SignOut">
        <span>Hello {{ user.firstName }}</span>
        <v-icon right>mdi-logout</v-icon>
      </v-btn> -->
      <template v-slot:extension v-if="showMobileSearch && !userIsAgent">
        <div v-if="showMobileSearch" class="d-md-none" style="background: #7b7b84; width: 100%; padding-bottom: 8px">
          <GlobalSearch :navigateOnClick="navigateOnClick" />
        </div>
      </template>
    </v-app-bar>

    <v-navigation-drawer class="navigation" app v-model="showMenu" :color="$servicex.branding.color">
      <v-list>
        <v-list-item v-for="link in userLinks" :key="link.text" class="list-item">
          <v-list-item-icon @click="navigateTo(link.route)">
            <v-icon class="white--text">{{ link.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content @click="navigateTo(link.route)">
            <v-list-item-title class="white--text">
              <div>{{ link.text }} <sup v-if="link.superscript" class="superscript">{{ link.superscript }}</sup>
              </div>
            </v-list-item-title>
          </v-list-item-content>

          <div v-if="link.sublink" @click="navigateTo(link.sublink.route)">
            <v-btn icon color="white"><v-icon>{{ link.sublink.icon }}</v-icon></v-btn>
          </div>

        </v-list-item>



        <v-list-group v-if="managementGroupLinks.length !== 0" color="white" :value="false" class="navbar-list-group">
          <template v-slot:activator>
            <v-icon slot="prependIcon" class="mr-8" color="white">mdi-cog-outline</v-icon>
            <v-list-item-title class="white--text">Administration</v-list-item-title>
          </template>

          <v-list-item v-for="link in managementGroupLinks" :key="link.text" router :to="link.route">
            <v-list-item-title class="white--text ml-4">
              {{ link.text }}
            </v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import { authStart } from "../config.js";
import { links } from "../models/links";
import GlobalSearch from "@/components/GlobalSearch.vue";
const brand = import("@/brands/" + process.env.VUE_APP_BRAND + '/theme')

export default {
  data() {
    return {
      bg: { color: this.$servicex.branding.color },
      drawer: false,
      isLoggedIn: false,
      links: [],
      showMobileSearch: false,
      userIsAgent: false,
      navigateOnClick: true,
    };
  },
  components: { GlobalSearch, 'brand': () => import('@/brands/' + (process.env.VUE_APP_BRAND || "default") + "/nav") },
  computed: {
    // isLoggedIn() {
    //   return this.$store.state.user != null;
    // },
    user() {
      return this.$store.state.user;
    },
    showMenu: {
      get: function () {
        return this.drawer && this.isLoggedIn;
      },
      set: function (val) {
        this.drawer = val;
      },
    },
    availableLinks() {
      return this.links.filter(
        (i) =>
          i.roles == null ||
          i.roles.some(
            (r) =>
              this.$store.state.user != null &&
              this.$store.state.user.roles != null &&
              this.$store.state.user.roles.indexOf(r) >= 0
          )
      );
    },
    userLinks() {
      return this.availableLinks.filter(i => i.group === undefined);
    },
    managementGroupLinks() {
      return this.availableLinks.filter(i => i.group !== undefined && i.group === 'Management');
    }
  },
  async created() {
    this.userIsAgent = false;
    if (this.$store.state.user && this.$store.state.user.roles)
      if (this.$store.state.user.roles.length === 1)
        if (this.$store.state.user.roles[0] === "JobManager.Agent") {
          this.userIsAgent = true;
        }

    this.init();
  },
  watch: {
    $route: "init",
  },

  methods: {
    navigateTo(route) {
      this.$router.push(route).catch(err => { console.log(err) });
    },
    SignIn() {
      window.location = authStart;
    },
    init() {
      this.links = links;
      this.isLoggedIn = this.$store.state.user != null;
      if (this.isLoggedIn) {
        this.drawer = window.innerWidth >= 600;
      }
    },
    goHome() {
      this.$router.push({ name: "home" });
    },
    SignOut() {
      this.$store.commit("user", null);
      this.$store.commit("accessToken", null);
      this.drawer = false;
      this.isLoggedIn = false;
      this.$nextTick(function () {
        this.$router.push({ name: "home" });
      });
    },
  },
};
</script>

<style scoped>
.list-item:hover {
  cursor: pointer;
  background-color: #00000008;
}

.superscript {
  text-transform: uppercase;
}
</style>

<style>
.navbar-list-group .mdi-chevron-down {
  color: white !important;
  margin-right: 5px;
}
</style>
