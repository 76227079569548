const brand = import("@/brands/" + process.env.VUE_APP_BRAND + '/theme');
const jobBrandNameSingle = (await brand).default.jobBrandNameSingle;
const jobBrandNamePlural = (await brand).default.jobBrandNamePlural;
const customerBrandNameSingle = (await brand).default.customerBrandNameSingle;
const customerBrandNamePlural = (await brand).default.customerBrandNamePlural;
const isProjectTabOn = (await brand).default.projectTab;
const isMapTabOn = (await brand).default.mapTab;

let links = [
  { icon: "mdi-home", text: "Home", route: "/" },
  {
    icon: "mdi-account-hard-hat",
    text: `My ${jobBrandNamePlural}`,
    subtitle: `View the ${jobBrandNameSingle.toLowerCase()} assigned to you and enter ${jobBrandNameSingle.toLowerCase()} sheets, view notes and photos.`,
    route: "/jobListAgent",
    roles: ["JobManager.Agent"],
    color: 'blue'
  },
  {
    icon: "mdi-format-list-bulleted",
    text: jobBrandNamePlural,
    subtitle: `Manage ${jobBrandNamePlural.toLowerCase()}, assign to agents. Review notes, ${jobBrandNameSingle.toLowerCase()} sheets and more.`,
    route: "/jobListManager",
    roles: ["JobManager.Manager"],
    sublink:
    {
      icon: "mdi-plus",
      text: `New ${jobBrandNameSingle}`,
      subtitle: `Enther the details for a New ${jobBrandNameSingle}.`,
      route: "/CreateJob",
      roles: ["JobManager.Manager"],
      showOnDashboard: false
    },
  },
  {
    icon: "mdi-calendar",
    text: "Calendar",
    subtitle: "See how your agents' days are looking in a easy to view calendar.",
    route: "/JobCalendar",
    color: 'purple',
    roles: ["JobManager.Manager"]
  },
  {
    icon: "mdi-home-city-outline",
    text: customerBrandNamePlural,
    subtitle: `Manage your ${customerBrandNamePlural.toLowerCase()}.`,
    route: "/customers",
    roles: ["JobManager.Manager"],
    color: 'blue'
  },
  {
    // icon: "mdi-tools",
    text: "Tools",
    subtitle: `Maintain the list of tools available to agents when completing ${jobBrandNameSingle.toLowerCase()} sheets.`,
    group: "Management",
    route: "/tools",
    roles: ["JobManager.Manager"],
    color: 'accent'
  },
  {
    // icon: "mdi-shopping-outline",
    text: "Suppliers",
    subtitle: `Maintain the list of known suppliers available to agents when completing ${jobBrandNameSingle.toLowerCase()} sheets.`,
    group: "Management",
    route: "/suppliers",
    roles: ["JobManager.Manager"],
    color: 'purple'
  },
  {
    text: `${jobBrandNameSingle} Settings`,
    subtitle: `Maintain the lists of ${jobBrandNameSingle.toLowerCase()} related information.`,
    group: "Management",
    route: "/jobInfoManagement",
    roles: ["JobManager.Administrator"],
    color: 'accent'
  },

  {
    text: `${customerBrandNameSingle} Settings`,
    subtitle: `Maintain the lists of ${customerBrandNameSingle.toLowerCase()} related information.`,
    group: "Management",
    route: "/customerInfoManagement",
    roles: ["JobManager.Administrator"],
    color: 'accent'
  },
  {
    icon: "mdi-account-group",
    text: "Users",
    subtitle: "Maintain users of the system.",
    route: "/users",
    group: "Management",
    roles: ["JobManager.Administrator"],
    color: 'purple'
  },
]

if (isProjectTabOn) {
  let project = {
    icon: "mdi-folder-multiple-outline",
    text: "Projects",
    subtitle: "Manage projects. Review tasks, notes, and more.",
    route: "/Projects",
    roles: ["JobManager.Manager"],
    showOnDashboard: false,
    superscript: "Beta",
    sublink:
    {
      icon: "mdi-plus",
      text: "New Project",
      subtitle: "Enther the details for a New Project.",
      route: "/CreateProject",
      roles: ["JobManager.Manager"],
      showOnDashboard: false
    }
  };

  let index = links.findIndex(link => link.route === "/jobListManager",);
  if (index !== -1) {
    links.splice(index + 1, 0, project);
  }

  let projectSettings = {
    text: "Project Settings",
    subtitle: "Maintain the lists of project related information.",
    group: "Management",
    route: "/projectInfoManagement",
    roles: ["JobManager.Administrator"],
    color: 'accent'
  };

  index = links.findIndex(link => link.route === '/jobInfoManagement');
  if (index !== -1) {
    links.splice(index + 1, 0, projectSettings);
  }
}

if (isMapTabOn) {
  let map = {
    icon: "mdi-map",
    text: "Map",
    subtitle: "See where your colleagues are located in case you need a hand.",
    route: "/LastLocationsMap",
    color: 'green'
  };

  let index = links.findIndex(link => link.route === '/JobCalendar');
  if (index !== -1) {
    links.splice(index + 1, 0, map);
  }

}

export { links };