import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import App from "./App.vue";
import router from "./router";
import "./registerServiceWorker";
import { ValidationProvider, extend } from "vee-validate/dist/vee-validate.full.esm";
import { ValidationObserver } from "vee-validate";
import { apiHost } from "./config.js";
import draggable from 'vuedraggable';

//import { extend } from 'vee-validate'

extend("time", {
  validate: value => value.match(/^([01]?[0-9]|2[0-3])([\:\.\s]?([0-5][0-9]))?(pm|am)?$/i),
  message: "Invalid time"
});
extend("timeSpent", {
  validate: value => {
    var match = value.trim().match(/^(((([1-9][0-9]{0,2}(\.[0-9]{1,2})?)[\s]*h?[o]?[u]?[r]?[s]?)?)|(([0-9]{1}[\s]?da?y?s?)?\s*([1-9]?[0-9]{1,2}[\s]*ho?u?[r]?[s]?)?\s*([0-9]{1,2}[\s]*mi?n?s?)?))$/)
    if (match?.some(i => i != null && i != '')) {
      return true;
    }
    return false;
  }, message: "Invalid time spent"
})
extend("greaterThanStartTime", {
  validate: function (endTime, { startTime }) {
    if (startTime === undefined || startTime === null || startTime === '') return true;

    const [startHours, startMinutes] = startTime.split(':').map(str => parseInt(str));
    const [endHours, endMinutes] = endTime.split(':').map(str => parseInt(str));
    const startTimeDate = new Date(2000, 0, 1, startHours, startMinutes || 0);
    const endTimeDate = new Date(2000, 0, 1, endHours, endMinutes || 0);
    return endTimeDate > startTimeDate;
  },
  params: ["startTime"],
  message: "End time must be greater than start time",
});

//window.Event = new Vue();
import store from "./store/index";
let brand = null
if (process.env.VUE_APP_BRAND) {
  brand = import("@/brands/" + process.env.VUE_APP_BRAND + '/theme')
} else {
  brand = import("@/brands/default/theme")
}
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component('draggable', draggable);
Vue.prototype.$servicex = { branding: { color: null } }
// TODO: set branding colours
const opts = {
  theme: {
    themes: {
      light: {
        primary: '#FF9800',
        secondary: '#607D8B',
        accent: '1E88E5',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      }
    }
  }
};
Vue.use(Vuetify);
Vue.use(store);
Vue.config.root = apiHost;

Vue.config.productionTip = false;


new Vue({
  beforeCreate() {
  },
  router,
  store,
  vuetify: new Vuetify(opts),
  created: async function () {
    this.$set(this.$servicex, 'branding', (await brand).default);
  },
  render: h => h(App)
}).$mount("#app");
